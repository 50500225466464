import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

/**
 * An abstract base class for services. Makes getting/sending data a bit more simple.
 */
export abstract class BaseService {
  /**
   * The base URL to be used in the calls. Will be appended to the API URL.
   */
  protected baseUrl = '';

  protected constructor(protected http: HttpClient) {}

  protected static mapToCsv(value: string): Blob {
    return new Blob([value], {type: 'text/csv'});
  }

  protected static mapToJson(value: string): Blob {
    return new Blob([value], {type: 'text/json'});
  }

  protected static mapToPDF(value: string): Blob {
    return new Blob([value], {type: 'text/pdf'});
  }

  protected static mapToXML(value: string): Blob {
    return new Blob([value], {type: 'application/xml'});
  }

  public get$<T>(url: string, headers?: HttpHeaders, params?: HttpParams | {[param: string]: string | string[]}): Observable<T> {
    return this.http.get<T>(this.baseUrl + url, {headers, params});
  }

  public getFullResponse$<T>(
    url: string,
    headers?: HttpHeaders,
    params?: HttpParams,
    observe: 'response' = 'response'
  ): Observable<HttpResponse<T>> {
    return this.http.get<T>(this.baseUrl + url, {headers, params, observe});
  }

  public post$<T>(url: string, body: any, headers?: HttpHeaders): Observable<T> {
    return this.http.post<T>(this.baseUrl + url, body, {headers});
  }

  public put$<T>(url: string, body: any, headers?: HttpHeaders): Observable<T> {
    return this.http.put<T>(this.baseUrl + url, body, {headers});
  }

  public delete$<T>(url: string): Observable<T> {
    return this.http.delete<T>(this.baseUrl + url);
  }

  public deleteWithBody$<T>(url: string, body: unknown): Observable<T> {
    return this.http.delete<T>(this.baseUrl + url, {body: body});
  }

  public getCsv$(url: string): Observable<Blob> {
    return this.http.get(this.baseUrl + url, {responseType: 'text'}).pipe(map(BaseService.mapToCsv));
  }

  public getJson$(url: string): Observable<Blob> {
    return this.http.get(this.baseUrl + url, {responseType: 'text'}).pipe(map(BaseService.mapToJson));
  }

  public postCsv$(url: string, filter: any): Observable<Blob> {
    return this.http.post(this.baseUrl + url, filter, {responseType: 'text'}).pipe(map(BaseService.mapToCsv));
  }
}

/**
 * A base class to be used for updating form data.
 */
export class EntityWithPassword {
  constructor(
    private entity: any,
    private password: string | undefined,
    private passCode?: string
  ) {}
}
